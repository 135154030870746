/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { CiPlay1 } from 'react-icons/ci';
import styles from './CoursePage.module.scss';

import imageStubLight from '../../assets/images/themes/light/course_stub_1920x1080.png';
import imageStubDark from '../../assets/images/themes/dark/course_stub_1920x1080.png';

import useCourses from '../../hook/useCourses';
import useAuth from '../../hook/useAuth';
import useError from '../../hook/useError';
import useTheme from '../../hook/useTheme';

import RenderHTML from '../../components/RenderHTML/RenderHTML';
import VideoList from '../../components/VideoList/VideoList';
import Button, {
  ButtonTypeEnum,
  TargetEnum,
} from '../../components/Button/Button';
import { ImageParamsEnum, ImageSizeEnum } from '../../utils/settings';

import { buildRoute as buildVideoRoute } from '../VideoPage/VideoPage.Route';
import { themesEnum } from '../../hoc/ThemeProvider';

/**
 * Selected Course page
 * @component
 */
function CoursePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const params = useParams();
  const { user } = useAuth();
  const { raiseError } = useError();
  const { theme } = useTheme();
  const { course, videos, video, loadCourseById, subscribeToCourse } =
    useCourses();

  const navigate = useNavigate();

  const handleSubscribe = async () => {
    if (course.isFree && !course.isPurchased) {
      setLoading(true);
      try {
        await subscribeToCourse(course.id);
        setIsSubscribed(true);
        navigate(`/mycourses/${course.id}/${videos[0].id}`);
      } catch (error) {
        console.error('Subscription failed', error);
      } finally {
        setLoading(false);
      }
    } else if (course && videos.length > 0) {
      navigate(`/mycourses/${course.id}/${videos[0].id}`);
    }
  };

  // Load course on user change only
  useEffect(() => {
    if (user) {
      if (!course || course.id !== params.courseId) {
        setIsLoading(true);
        loadCourseById(params.courseId);
      } else {
        setIsLoading(false);
      }
    }
  }, [user, course, params.courseId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (typeof course === 'undefined') {
      raiseError(404, 'Not found');
    }
  }, [course]);

  // Prepare course title image object
  const image = useMemo(() => {
    const imgObject = course?.image?.instances?.[ImageSizeEnum.full];
    if (isLoading || !imgObject) {
      let imageStub = imageStubLight;
      switch (theme) {
        case themesEnum.DARK:
          imageStub = imageStubDark;
          break;
        case themesEnum.LIGHT:
        default:
          imageStub = imageStubLight;
          break;
      }

      return {
        url: imageStub,
        width: ImageParamsEnum[ImageSizeEnum.full].width,
        height: ImageParamsEnum[ImageSizeEnum.full].height,
        alt: 'Изображение загружается',
      };
    }
    return imgObject;
  }, [course, isLoading, theme]);

  // Prepare course button
  const actionButton = useMemo(() => {
    if (!course) {
      return <Skeleton />;
    }
    if (course.downloadLink?.length) {
      return (
        <Button to={course.downloadLink} target={TargetEnum.blank}>
          Скачать
        </Button>
      );
    }
    if (course.isFree && !course.downloadLink) {
      return (
        <Button
          type={ButtonTypeEnum.colorful}
          onClick={handleSubscribe}
          className={styles.autoSubBtn}
        >
          <CiPlay1 className={styles.ciIcon} height={20} width={20} />
          Перейти к просмотру
        </Button>
      );
    }
    if (course.isPurchased && !course.downloadLink) {
      if (videos.length) {
        const targetUrl = buildVideoRoute(course, videos[0]);
        return (
          <Button
            onClick={() => navigate(targetUrl)}
            type={ButtonTypeEnum.colorful}
          >
            Перейти к просмотру
          </Button>
        );
      }
      return null;
    }

    return (
      <Button to={course.paymentLink} target={TargetEnum.blank}>
        Приобрести курс
      </Button>
    );
  }, [course, videos]);

  return (
    <div className={styles.holder}>
      <div className={styles.courseDescription}>
        <div className={styles.coverImage}>
          <img
            src={image.url}
            width={image.width}
            height={image.height}
            alt={image.alt || course?.title}
          />
        </div>
        <h1 className={styles.title}>
          {(course && <span>{course && course?.title}</span>) || <Skeleton />}
        </h1>
        {course?.downloadLink && (
          <Button
            className={styles.downloadButton}
            to={course.downloadLink}
            target={TargetEnum.blank}
          >
            Скачать
          </Button>
        )}
        {!course?.downloadLink && course?.purchasedAt && (
          <div style={{ width: '200px' }}>
            <Button
              onClick={() => {
                const targetUrl = buildVideoRoute(course, videos[0]);
                navigate(targetUrl);
              }}
              type={ButtonTypeEnum.colorful}
            >
              Перейти к просмотру
            </Button>
          </div>
        )}
        <div className={styles.description}>
          {(course && <RenderHTML htmlString={course?.description} />) || (
            <Skeleton count={10} />
          )}
        </div>
        <div className={styles.buttonHolder}>{actionButton}</div>
      </div>
      <div className={styles.courseContent}>
        <h2 className={styles.videosHeading}>Содержание</h2>
        {course && course?.videosCount ? (
          <VideoList className={styles.videoList} course={course} />
        ) : null}
      </div>
    </div>
  );
}

CoursePage.propTypes = {};

export default CoursePage;
