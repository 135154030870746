import PropTypes from 'prop-types';
import styles from '../StreamPage.module.scss';

export function VideoSection({ day }) {
  return (
    <div className={styles.infoSection}>
      <iframe
        title="Показательный мастер-класс по технологии FaceTite"
        src={`https://facecast.net/w/${day === 1 ? 'e36tph' : 'z5srzt'}`}
        width="100%"
        allow="autoplay; fullscreen"
        className={styles.video}
        allowFullScreen
      />
      <span className={styles.secondTitle}>
        {day === 1
          ? 'Часть 1. Женская сексуальная функция и дисфункция - возможности современного холистического подхода. Модуль 1. День 1'
          : 'Женская сексуальная функция и дисфункция - возможности современного холистического подхода. Модуль 1. День 2'}
      </span>
      <div className={styles.courseDescription}>
        <div>
          Модуль направлен на анализ проблемы женских сексуальных дисфункций и современных подходов к их лечению. В первом модуле мы разберем физиологию женской сексуальности, ее гормональную и психосексуальную основу, а также детально раскроем подходы к лечению проблем нарушения либидо, любрикации и возбуждения, аноргазмии, а также болевых сексуальных дисфункций.
        </div>
      </div>
    </div>
  );
}

VideoSection.propTypes = {
  day: PropTypes.number.isRequired,
};

VideoSection.defaultProps = {
};
