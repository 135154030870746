import PropTypes from 'prop-types';
import { joinClasses } from '../../../utils/helper';
import styles from '../StreamPage.module.scss';
import din from '../../../assets/images/din.png';
import fed from '../../../assets/images/fed.png';
import gel from '../../../assets/images/gel.png';
import gurb from '../../../assets/images/gurb.png';
import karp from '../../../assets/images/karp.png';
import kos from '../../../assets/images/kos.png';
import lesh from '../../../assets/images/lesh.png';
import leuh from '../../../assets/images/leuh.png';
import perm from '../../../assets/images/perm.png';
import pril from '../../../assets/images/pril.png';
import shat from '../../../assets/images/shat.png';

// import useWindowSize from '../../../hook/useWindowSize';
// import { desktopScreenWidth } from '../../../utils/consts';
import { PersonComponent } from './PersonComponent';

const trainersDayTwoInfo = [
  {
    avatar: perm,
    name: 'Пермяков Артём Сергеевич',
    description: 'К.м.н., врач-акушер-гинеколог, уролог, психолог, президент Ассоциации Урогинекологов (АУГ), психолог, основатель школы эстетической урогинекологии “de Novo” для врачей, основатель онлайн-школы для пациентов по реабилитации тазового дна и закрытого женского клуба «Азбука Здоровья»',
  },
  {
    avatar: shat,
    name: 'Шатилова Маргарита Сергеевна',
    description: 'Врач-реабилитолог, специалист по послеродовому восстановлению, автор курсов по тазовому дну для специалистов и пациентов',
  },
  {
    avatar: gel,
    name: ' Геллер Елена Семеновна',
    description: 'Врач акушер-гинеколог, гинеколог-эндокринолог, сексолог, специалист в эстетической гинекологии, член АСЭГ, врач ультразвуковой диагностики.',
  },
  {
    avatar: lesh,
    name: 'Лешунов Евгений Викторович',
    description: 'Врач уролог, пластический хирург. Аспирант РМАНПО. Член правления ISRAIT',
  }, {
    avatar: fed,
    name: 'Федченко Евгения Викторовна',
    description: 'Акушер-гинеколог, врач превентивной, интегративной, антивозрастной медицины. Член Междисциплинарной Ассоциации специалистов антивозрастной медицины «МАСАМ». Член ассоциации PreventAge. Член Ассоциации Специалистов Эстетической Гинекологии. Сертифицированный тренер компании «Интер-Медикаль» по эстетической и реконструктивной гинекологии. Заведующая отделением «Коррекции веса и Anti-Age терапии» клиники «ЕЛЕНА»',
  }, {
    avatar: gurb,
    name: 'Гурбанова Фатима Хазеиновна',
    description: 'Врач акушер-гинеколог. Гинеколог-эндокринолог. Эксперт в области эстетической гинекологии. Эксперт в области эстетической гинекологии клуба здоровья и красоты HELMS.',
  },
];

const trainersDayOneInfo = [
  {
    avatar: lesh,
    name: 'Лешунов Евгений Викторович',
    description: 'Врач уролог, пластический хирург. Аспирант РМАНПО. Член правления ISRAIT',
  },
  {
    avatar: din,
    name: 'Динкелаккер Инна Артуровна',
    description: 'Врач гинеколог, эндокринолог, адепт превентивной и холистической медицины, гинеколог-сексолог, специалист по эстетической коррекции в гинекологии',
  },
  {
    avatar: kos,
    name: 'Косарева Элина Робертовна',
    description: 'Гинеколог, эндокринолог, хирург. Врач холистической медицины',
  },
  {
    avatar: pril,
    name: 'Прилуцкая Виктория Юрьевна',
    description: 'Врач гинеколог-эндокринолог, диетолог, член ISGE, РОСГЭМ, РОАГ. Автор ряда научных публикаций и блога о женском здоровье в Instagram @doctor_gyn_endo',
  }, {
    avatar: gel,
    name: ' Геллер Елена Семеновна',
    description: 'Врач акушер-гинеколог, гинеколог-эндокринолог, сексолог, специалист в эстетической гинекологии, член АСЭГ, врач ультразвуковой диагностики',
  }, {
    avatar: leuh,
    name: 'Леухина Ирина Алексеевна',
    description: 'Врач - онколог, химиотерапевт - врач-превентивной и интегративной медицины - член ассоциации врачей интегральной превентивной и антивозрастной медицины «PreventAge®️» V',
  }, {
    avatar: karp,
    name: 'Карпов Максим Олегович',
    description: 'Врач гинеколог-хирург ',
  },

];
// const supportInfo = [
//   {
//     avatar: support1,
//     name: 'Темлякова Татьяна',
//     phone: '+7 (905) 210-32-50',
//     email: 'tt@citmed.ru',
//   },
// ];

// const renderSupportCards = () => supportInfo.map((support) => <PersonComponent
//   avatar={support.avatar}
//   description={support.phone}
//   additionalInfo={support.email}
//   name={support.name}
//   hasLinks
// />);

const renderTrainersCards = (trainers) => trainers.map((trainer) => <PersonComponent
  avatar={trainer.avatar}
  description={trainer.description}
  name={trainer.name}
/>);

export function TrainersSection({ day }) {
  // const { width } = useWindowSize();

  return (
    <div
      className={joinClasses([styles.panel, styles.panelLeft])}
    >

      <div className={styles.trainersHolder}>
        <span className={styles.title}>
          Тренеры технологии
        </span>
        <div className={styles.wrapper}>
          {renderTrainersCards(day === 1 ? trainersDayOneInfo : trainersDayTwoInfo)}
        </div>
      </div>
      {/* <div className={styles.trainersHolder}> */}
      {/*  <span className={styles.title}> */}
      {/*    Всегда на связи */}
      {/*  </span> */}
      {/*  {width <= desktopScreenWidth */}
      {/*    ? <div className={styles.supportSection}> */}
      {/*      {renderSupportCards()} */}
      {/*      </div> */}
      {/*    : renderSupportCards()} */}
      {/* </div> */}
    </div>
  );
}

TrainersSection.propTypes = {
  day: PropTypes.number.isRequired,
};

TrainersSection.defaultProps = {
};
