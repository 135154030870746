import PropTypes from 'prop-types';

import styles from './CoursesPageContent.module.scss';

import { CourseShape } from '../../apis/models/course';

import Button from '../../components/Button/Button';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import CoursesGrid from '../CoursesGrid/CoursesGrid';
import CoursesNewColumn from '../CoursesNewColumn/CoursesNewColumn';
import { joinClasses } from '../../utils/helper';
import Search from '../../components/Search/Search';
import useCourses from '../../hook/useCourses';
import { Banner } from '../../components/Banner/Banner';

/**
 * Content for courses pages
 * @component
 */
function CoursesPageContent({
  title,
  courses,
  isLoading,
  onLazyLoad,
  nocourses,
  hideNewOnPhone,
}) {
  const { setSearchInProgress, searchInputText, searchInProgress } = useCourses();
  return (
    <div className={styles.holder}>
      <div
        className={joinClasses([styles.panel, styles.panelLeft, hideNewOnPhone ? styles.hideOnPhone : ''])}
      >
        <ProfileCard className={styles.profileCard} />
        <hr />
        <h2 className={styles.adaptiveHeading}>
          Новинки
        </h2>
        <CoursesNewColumn />
      </div>

      <div className={joinClasses([styles.panel, styles.panelMain])}>
        {title && (
          <h2 className={joinClasses([styles.allCoursesTitle])}>{title}</h2>
        )}

        <div className={styles.contentWrapper}>
          <Search />
          {/* <Banner
            day={2}
          />
          <Banner
            day={1}
          /> */}
          {(!isLoading && !courses?.length && !searchInProgress && !searchInputText?.length && (
            <div className={styles.noCourses}>
              <p>{nocourses.text}</p>
              <Button
                className={styles.btn}
                onClick={nocourses.onButton}
              >
                {nocourses.btn}
              </Button>
            </div>
          ))}
          {!setSearchInProgress
            && (
            <CoursesGrid
              courses={courses}
              isLoading={isLoading}
              onLazyLoad={onLazyLoad}
            />
            )}
        </div>
      </div>
    </div>
  );
}

CoursesPageContent.propTypes = {
  title: PropTypes.string,
  courses: PropTypes.arrayOf(CourseShape).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onLazyLoad: PropTypes.func.isRequired,
  nocourses: PropTypes.shape({
    text: PropTypes.string,
    btn: PropTypes.string,
    onButton: PropTypes.func,
  }),
  hideNewOnPhone: PropTypes.bool,
};

CoursesPageContent.defaultProps = {
  title: '',
  nocourses: {
    text: 'Курсы отсутствуют',
    btn: 'На главную',
    onButton: () => null,
  },
  hideNewOnPhone: false,
};

export default CoursesPageContent;
