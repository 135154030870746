/* eslint-disable react/jsx-one-expression-per-line */
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './Breadcrumbs.module.scss';

import useCourses from '../../hook/useCourses';
import { joinClasses } from '../../utils/helper';

const BreadcrumbsAliases = {
  profile: 'Профиль',
  personal: 'Настройка личных данных',
  changepassword: 'Изменение пароля',
  mycourses: 'Мои курсы',
  free: 'Бесплатные',
  'admin-question': 'Вопрос администратору',
  stream: 'Женская сексуальная функция и дисфункция — возможности современного холистического подхода. Модуль 1',
};

const CrumbItemTypeEnum = {
  course: 'course',
  video: 'video',
  section: 'section',
  notfound: 'notfound',
  stream: 'stream',
};

const MAX_CRUMB_SIZE = 64;
const HELLIP = String.fromCharCode('0x02026');
const delimiter = (<span className={styles.delimeter}>&thinsp;</span>);

/**
 * Represent current path in web app hierarchy with links to parent pages
 * @component
 */
function Breadcrumbs({ className }) {
  const { course, video, videos } = useCourses();

  const location = useLocation();
  /**
   * Get text representation of crumb
   * @param {String} crumb Part of the path
   * @returns Text for crumb segment
   */
  const getItem = (crumb) => {
    if (crumb in BreadcrumbsAliases) {
      return {
        text: BreadcrumbsAliases[crumb],
        type: CrumbItemTypeEnum.section,
      };
    }
    // if crumb equal to course ID
    if (course && crumb === course.id) {
      const shortText = course?.title?.slice(0, MAX_CRUMB_SIZE);
      return {
        text: shortText.length !== course?.title?.length ? `${shortText}${HELLIP}` : shortText,
        type: CrumbItemTypeEnum.course,
      };
    }
    // If crumb equal to the loaded video ID
    if (video && crumb === video.id) {
      const shortText = video?.title?.slice(0, MAX_CRUMB_SIZE);
      return {
        text: shortText.length !== video?.title?.length ? `${shortText}${HELLIP}` : shortText,
        type: CrumbItemTypeEnum.video,
      };
    }
    // If crumb equal to one of loaded videos ID
    if (videos) {
      const found = videos.find((v) => crumb === v.id);
      if (found) {
        const shortText = found?.title?.slice(0, MAX_CRUMB_SIZE);
        return {
          text: shortText.length !== found?.title?.length ? `${shortText}${HELLIP}` : shortText,
          type: CrumbItemTypeEnum.video,
        };
      }
    }
    return {
      text: crumb,
      type: CrumbItemTypeEnum.notfound,
    };
  };

  const crumbs = useMemo(() => {
    let currentLink = '';
    return location.pathname
      .split('/')
      .filter((crumb) => crumb !== '')
      .map((crumb, i, { length }) => {
        currentLink += `/${crumb}`;
        const item = getItem(crumb);
        return (
          <div className={styles.block} key={currentLink} data-type={item.type}>
            {((i === length - 1) && ( // Last element
              <span className={styles.item}>
                <div className={styles.label}>
                  {item.text}
                </div>
              </span>
            )) || (
              <Link className={styles.item} to={currentLink} data-type={item.type}>
                <div className={styles.label}>
                  {item.text}
                  {delimiter}
                </div>
              </Link>
            )}
          </div>
        );
      });
  }, [location, course, video, videos]);

  if (crumbs[0]?.key === '/stream' || crumbs.length > 1
    || (crumbs.length === 1 && crumbs[0]?.props?.['data-type'] === CrumbItemTypeEnum.course)) {
    return (
      <div className={joinClasses([styles.breadcrumbs, className])}>
        <div className={styles.block}>
          <Link className={styles.item} to="/">
            <span className={styles.label}>Главная</span>
            {delimiter}
          </Link>
        </div>
        {crumbs}
      </div>
    );
  }

  return '';
}

Breadcrumbs.propTypes = {
  // Custom class name
  className: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  className: '',
};

export default Breadcrumbs;
