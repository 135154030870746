import PropTypes from 'prop-types';
import { joinClasses } from '../../../utils/helper';
import styles from '../StreamPage.module.scss';
import { Schedule } from './Schedule';
import { VideoSection } from './VideoSection';

export function MainSection({ day }) {
  return (
    <div className={joinClasses([styles.panelMain])}>
      <VideoSection day={day} />
      <Schedule day={day} />
    </div>
  );
}

MainSection.propTypes = {
  day: PropTypes.number.isRequired,
};

MainSection.defaultProps = {};
