import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../layouts/CoursesPageContent/CoursesPageContent.module.scss';
import {
  getRoute as getStreamRoute,
} from '../../containers/StreamPage/StreamPage.Route';
import { tabletScreenWidth } from '../../utils/consts';
import useWindowSize from '../../hook/useWindowSize';
import useTheme from '../../hook/useTheme';
import { themesEnum } from '../../hoc/ThemeProvider';
import playButtonLight from '../../assets/icons/themes/light/play-button.svg';
import playButtonDark from '../../assets/icons/themes/dark/play-button.svg';
import { joinClasses } from '../../utils/helper';
import liveIcon from '../../assets/icons/live.svg';
import useStreams from '../../hook/useStreams';

import image1 from '../../assets/images/d1.jpg';
import image2 from '../../assets/images/d2.jpg';

const isLive = (day) => {
  const date = new Date();
  return day === date.getDate();
};

const livePlug = <span className={styles.dateWrapper}>
  <img src={liveIcon} alt="Icon" />
  {' '}
  В Эфире
</span>;

const dayTwo = {
  title: 'Часть 2. Женская сексуальная функция и дисфункция — возможности современного холистического подхода',
  subtitle: <span className={styles.dateWrapper}>
    Модуль 1. Второй день:
    <span className={styles.mobilePlug} style={{ fontWeight: 'bold' }}>
      {' '}
      {isLive(22) ? livePlug : '22 сентября'}
    </span>
  </span>,
  speakers: 'Пермяков Артемий Сергеевич, Шатилова Маргарита Сергеевна, Федченко Евгения Викторовна, Гурбанова Фатима Хазеиновна, Лешунов Евгений Викторович',
  organizer: 'КИТ МЕД',
};

const dayOne = {
  title: 'ЧАСТЬ 1. Женская сексуальная функция и дисфункция — возможности современного холистического подхода',
  subtitle: <span className={styles.dateWrapper}>
    Модуль 1. Первый день:
    <span className={styles.mobilePlug} style={{ fontWeight: 'bold' }}>
      {' '}
      {isLive(21) ? livePlug : '21 сентября'}
    </span>
  </span>,
  speakers: 'Сазонова Ирина Евгеньевна, Лешунов Евгений, Динкелаккер Инна Артуровна, Косарева Элина Робертовна, Прилуцкая Виктория Юрьевна, Геллер Елена Семеновна,  Леухина Ирина Алексеевна, Карпов Максим Олегович',
  organizer: 'КИТ МЕД',
};

// const streamButtonText = (day) => {
//   const date = new Date();

//   if (day === date.getDate()) {
//     return <span className={styles.oneLine}>
//       <img src={playIconRed} alt="Перейти" draggable="false" />
//       Смотреть эфир
//     </span>;
//   }
//   if (day > date.getDate()) {
//     return 'Подробнее...';
//   }
//   return 'Смотреть запись эфира';
// };

export function Banner({ day }) {
  const {
    title, organizer, speakers, subtitle,
  } = day === 1 ? dayOne : dayTwo;
  const { changeDay } = useStreams();
  const { width } = useWindowSize();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  const renderTitle = () => {
    if (width <= 1050 && width >= 1024) {
      return `${title.slice(0, 50)}...`;
    }
    return title;
  };

  const preview = <button
    onMouseEnter={() => setShowButton(true)}
    onMouseLeave={() => setShowButton(false)}
    type="button"
    onClick={() => {
      changeDay(day);
      navigate(getStreamRoute());
    }}
    className={
joinClasses([styles.bannerImage, day === 1 ? styles.firstDayBg : styles.secondDayBg])
}>
  <img src={day === 1 ? image1 : image2} width='100%' height='100%' />
  </button>

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      {width <= 720 && preview}
      <div
        onClick={() => {
          changeDay(day);
          navigate(getStreamRoute());
        }}
        className={styles.banner}
      >
        {width > 720 && preview}
        <div className={styles.bannerTextWrapper}>
          <span className={styles.bannerTitle}>
            {renderTitle()}
          </span>
          <div className={styles.bannerTexts}>
            <span>
              {subtitle}
            </span>
            <span className={joinClasses([styles.text, styles.speakersWrapper])}>
              <span className={styles.darkText}>
                Спикеры:
                {' '}
              </span>
              {speakers}
            </span>

            <span className={styles.text}>
              <span className={styles.darkText}>
                Организатор:
                {' '}
              </span>
              {organizer}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

Banner.propTypes = {
  day: PropTypes.number.isRequired,
};

Banner.defaultProps = {};
