import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const StreamsContext = createContext(null);

export function StreamsProvider({ children }) {
  const [day, setDay] = useState(1);
  const changeDay = (d) => {
    setDay(d);
  };

  const value = useMemo(() => ({
    day,
    changeDay,
  }), [day, changeDay]);

  return <StreamsContext.Provider value={value}>{children}</StreamsContext.Provider>;
}

StreamsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
