import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from '../StreamPage.module.scss';
import { joinClasses } from '../../../utils/helper';
import timeDark from '../../../assets/icons/themes/dark/timeDark.svg';
import timeLight from '../../../assets/icons/themes/light/timeLight.svg';
import timeDarkG from '../../../assets/icons/themes/light/greenTimer.svg';
import timeLightP from '../../../assets/icons/themes/light/purpleTImer.svg';
import useTheme from '../../../hook/useTheme';
import { themesEnum } from '../../../hoc/ThemeProvider';
import useWindowSize from '../../../hook/useWindowSize';
import { tabletScreenWidth } from '../../../utils/consts';

const dayTwoSchedule = [
  {
    time: '10:00 – 10:45',
    speaker: 'Пермяков Артемий Сергеевич',
    description: 'Психофизиологическое равновесие, как целостный подход к здоровью',
  },
  {
    time: '10:45 – 11:45',
    speaker: 'Пермяков Артемий Сергеевич',
    description: 'Биомеханика тела, как ключ к восстановлению женского здоровья',
  },
  {
    time: '11:45 – 12:45',
    speaker: 'Шатилова Маргарита Сергеевна',
    description: 'Тазовое дно и сексуальные дисфункции',
  },
  {
    time: '12:45 – 13:30 — Обед',
  },
  {
    time: '13:30 – 14:15',
    speaker: 'Геллер Елена Семеновна',
    description: 'Сексуальные дисфункции в практике врача гинеколога. Мой опыт',
  },
  {
    time: '14:15 – 15:00',
    speaker: 'Федченко Евгения Викторовна',
    description: 'Сексуальные дисфункции в практике врача гинеколога. Мой опыт',
  },
  {
    time: '15:00 – 15:30',
    speaker: 'Гурбанова Фатима Хазеиновна',
    description: 'Сексуальные дисфункции в практике врача гинеколога. Мой опыт',
  },
  {
    time: '15:30 – 15:45 — Кофе-брейк',
  },
  {
    time: '15:45 – 16:45',
    speaker: 'Лешунов Евгений Викторович',
    description: 'Мастер класс по применению платформы eMPower: FormaV, Morpheus8V, VTone для лечения сексуальных дисфункций',
  },
  {
    time: '16:45 – 17:45',
    speaker: 'Пермяков Артемий Сергеевич',
    description: 'Мастер класс по применению СО2-лазера для лечения сексуальных дисфункций',
  },
  {
    time: '17:45 – 18:00 — вопросы-ответы',
  },
  {
    time: '18:00 — выдача дипломов',
  },
];

const dayOneSchedule = [
  {
    time: '10:00-11:00',
    speaker: 'Лешунов Евгений Викторович',
    description: 'Холистический подход в управлении сексуальным здоровьем женщины',
  },
  {
    time: '11:00 – 11:45',
    speaker: 'Динкелаккер Инна Артуровна',
    description: 'Гормональный баланс женщины как мощный инструмент биохакинга. Разбор клинических кейсов',
  },
  {
    time: '11:45 – 12:45',
    speaker: 'Косарева Элина Робертовна',
    description: 'Саплиментарная поддержка психосексуальных дисфункций',
  },
  {
    time: '12:45 – 13:30 — Обед',
    speaker: '',
    description: '',
  },
  {
    time: '13:30 – 14:00',
    speaker: 'Прилуцкая Виктория Юрьевна',
    description: 'Предменструальный синдром и сексуальная дисфункция',
  },
  {
    time: '14:00-14:45',
    speaker: 'Геллер Елена Семеновна',
    description: 'Сексуальное здоровье женщины в разные периоды жизни',
  },
  {
    time: '14:45 – 15:30',
    speaker: 'Динкелаккер Инна Артуровна',
    description: 'Возрастные сексуальные дисфункции и нейрогенетика, как важный аспект сексуальности',
  },
  {
    time: '15:30 – 16:00',
    speaker: 'Леухина Ирина Алексеевна',
    description: 'Сексуальная дисфункция у пациентов после лечения онкологии. Возможности реабилитации и комплексного восстановления',
  },
  {
    time: '16:00 – 16:15 — Кофе-брейк',
  },
  {
    time: '16:15 – 17:00',
    speaker: 'Карпов Максим Олегович',
    description: 'Сексуальные дисфункции в практике врача-гинеколога',
  },
  {
    time: '17:00 – 17:30',
    speaker: 'Косарева Элина Робертовна',
    description: 'Дистрофические заболевания вульвы и сексуальные дисфункции',
  },
  {
    time: '17:30 – 17:45 — вопросы-ответы',
  },
  {
    time: '17:45 — фуршет',
  },
];

export function Schedule({ day }) {
  const { theme } = useTheme();
  const { width } = useWindowSize();

  const clocksIcon = useCallback((isHighlighted) => {
    switch (theme) {
      case themesEnum.DARK:
        return isHighlighted ? timeDarkG : timeLight;
      case themesEnum.LIGHT:
        return isHighlighted ? timeLightP : timeDark;
      default:
        return null;
    }
  }, [theme]);

  const renderSchedule = () => {
    const todaySchedule = day === 1 ? dayOneSchedule : dayTwoSchedule;
    return todaySchedule.map(({ time, speaker, description }, i) => <div className={styles.lineWrapper}>
      <div className={styles.schedule}>
        <div className={styles.timings}>
          <img src={clocksIcon(!speaker?.length)} alt="Icon" />
          <span
            className={`${styles.time} ${styles.bold} ${!speaker?.length ? styles.highlightedText : ''}`}
          >
            {time}
          </span>
        </div>
        {!!speaker?.length && width > tabletScreenWidth && <span>-</span>}
        <span className={styles.bold}>{speaker}</span>
      </div>
      <div>
        <span className={styles.descriptionText}>{description}</span>
        {i + 1 !== todaySchedule.length && <div className={styles.line} />}
      </div>
    </div>);
  };

  return (
    <div className={styles.infoSection}>
      <span
        className={joinClasses([styles.secondTitle, styles.titleWithSpacing])}
      >
        Программа мастер-класса
      </span>
      {renderSchedule()}
    </div>
  );
}

Schedule.propTypes = {
  day: PropTypes.number.isRequired,
};

Schedule.defaultProps = {};
