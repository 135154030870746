import { useEffect } from 'react';
import styles from './StreamPage.module.scss';
import { TrainersSection } from './components/TrainersSection';
import { MainSection } from './components/MainSection';
import useStreams from '../../hook/useStreams';

function StreamPage() {
  const { day } = useStreams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.holder}>
      <MainSection day={day} />
      <TrainersSection day={day} />
    </div>
  );
}

export default StreamPage;
