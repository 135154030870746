import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';

import App from './App';
import { AuthProvider } from './hoc/AuthProvider';
import { CoursesProvider } from './hoc/CoursesProvider';
import { ErrorProvider } from './hoc/ErrorProvider';
import { ThemeProvider } from './hoc/ThemeProvider';
import { StreamsProvider } from './hoc/StreamsProvider';

const root = createRoot(document.getElementById('root'));
document.body.classList.add('initialised');

// Get  baseName from ENV (consolidated environment variables prepared in webpack)
export const baseName = ENV?.PUBLIC_PATH ? ENV.PUBLIC_PATH : '/';
// console.log('ENV', ENV);

// A properly formatted basename should have a leading slash, but no trailing slash.
// https://v5.reactrouter.com/web/api/BrowserRouter/basename-string
const baseNameFixed = `/${baseName.replace(/^\/+|\/+$/g, '')}`;
// console.log('baseName', baseNameFixed);

//! Strict mode not compatible with ReactPlayer from "react-player"
// https://github.com/cookpete/react-player/issues/1520
root.render(
  <BrowserRouter basename={baseNameFixed}>
    <ErrorProvider>
      <AuthProvider>
        <ThemeProvider>
          <CoursesProvider>
            <StreamsProvider>
              <App />
            </StreamsProvider>
          </CoursesProvider>
        </ThemeProvider>
      </AuthProvider>
    </ErrorProvider>
  </BrowserRouter>,
);
